"use client";
import React, { useState, useEffect } from 'react';
import { parseISO } from 'date-fns';

function formatRelativeTime(date: Date) {
    const delta = Date.now() - date.getTime();
    const absoluteDelta = Math.abs(delta);
    const seconds = absoluteDelta / 1000;

    let result = "";
    if (seconds < 60) {
        result = `${Math.round(seconds)}s`;
    } else {
        const minutes = seconds / 60;
        if (minutes < 60) {
            result = `${Math.round(minutes)}m`;
        } else {
            const hours = minutes / 60;
            if (hours < 24) {
                result = `${Math.round(hours)}h`;
            } else {
                const days = hours / 24;
                if (days < 365) {
                    result = `${Math.round(days)}d`;
                } else {
                    result = `${Math.round(days / 365)}y`;
                }
            }
        }
    }

    return delta >= 0 ? `${result} ago` : `in ${result}`;
  };
  

function RelativeTime({date}: {date: string}) {
  const [relativeTime, setRelativeTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const localDate = parseISO(date);
      setRelativeTime(formatRelativeTime(localDate));
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000);

    return () => clearInterval(intervalId);
  }, [date]);

  return <span>{relativeTime}</span>;
};

export default RelativeTime;
